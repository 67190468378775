import CC from "cookieconsent"
import "../stylesheet/styles.scss"

window.cookieconsent.initialise({
  container: document.getElementById("cookie-content"),
  revokable: false,
  onStatusChange: function(status) {
    this.hasConsented() ?
    consentGranted() : consentDenied();
  },
  law: {
   regionalLaw: true,
  },
  location: true,
  palette: {
    popup: {background: '#fff', text: '#000', link: '#000'},
    button: {background: '#221074', text: '#fff'},
    highlight: {background: '#fff', text: '#000000'},
   },
  content: {
    header: "A weboldal cookie-kat használ",
    message: "A weboldalunkon cookie-kat használunk, hogy a legrelevánsabb élményt nyújtsuk, ismételt látogatásod esetére elmentjük az elfogadott beállításokat. Az Elfogadom gombra kattintva hozzájárulsz a cookie-k használatához.",
    allow: "Elfogadom!",
    deny: "Mégsem",
    link: "Részletek",
    href: "https://mostmulik.hu/privacy_policy",
    close: "&#x274c;",
    policy: " &#9881;"
  },
  theme: "classic",
  type: 'opt-out',
 });

function consentGranted() {
  gtag('consent', 'update', {
        'ad_storage': 'granted',
        'analytics_storage': 'granted'
      });
};


function consentDenied() {
  gtag('consent', 'update', {
        'ad_storage': 'denied',
        'analytics_storage': 'denied'
      });
};